<template>
  <div>
    <el-dialog :title="title||'分配权限'" width="800px" :visible.sync="isDialog" @close="dialogClose">
      <!-- 树形控件 -->
      <slot></slot>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogClose">取 消</el-button>
          <el-button type="primary" @click="dialogConfirmClick">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "dialog",
  props: {
    isDialog: Boolean | String,
    title:String
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    dialogConfirmClick() {
      this.$emit("dialogConfirmClick", "");
    },
    dialogClose() {
      this.$emit("dialogClose");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>